import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { toDataURL } from 'qrcode';

import AppHeader from '../components/AppHeader.jsx';
import ObjectCard from '../components/ObjectCard.jsx';

import { fetchObject } from '../services/inventory';
import { setColors, getConditionData, getStatusData } from '../libs/utils';

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import PageBreadcrumb from '../ui/Breadcrumb.jsx';

import Icon from '../ui/Icon.jsx';

function ObjectView() {

    const params = useParams();

	const [objectID, setObjectID] = useState("");
    const [object, setObject] = useState("");

    const [QRsource, setQRsource] = useState("");

    useEffect(() => {
		if(params.id) {
			setObjectID(params.id);
			fetchObject(params.id).then((response) => {
				setObject(response.result);
			});
		}
        const url = window.location+"";
        toDataURL(url).then(setQRsource);
	},[]);

    useLayoutEffect(() => {
        setColors();
    });

    return(
        <div className="App">
            <AppHeader />
            <section className="App-section">
                <Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
                            <h2>{object.name}</h2>
                            <PageBreadcrumb page="object" />
                        </div>
						<Link to={`/edit/${objectID}`} className="d-none d-sm-block ms-sm-auto"><Button variant="primary" size="lg"><Icon name="pencil" className="me-2" />Modifier</Button></Link>
				</Stack>
                <div className="mb-3 text-start">
                    <ObjectCard object={object} />
                </div>
                <div className="d-none d-sm-block">
                    <img src={QRsource} alt="QR Code" />
                </div>
                <div className="d-block d-sm-none">
                    <Link to={`/edit/${objectID}`} className="d-grid mt-4"><Button variant="primary" size="lg"><Icon name="pencil" className="me-2" />Modifier</Button></Link>
                </div>
                {/* <div className="mb-3 text-start">
                    <JSONPretty id="json-pretty" data={JSON.stringify(object,null,'\t')}></JSONPretty> 
                </div> */}
            </section>
        </div>
    );
}

export default ObjectView;