const selectStyles = {
    menuList: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadiusTop: 0
     }),
     menu: provided => ({
        ...provided,
        marginTop: 0,
        width: '100%',
        marginTop: '3px',
        // borderRadius: '0 0 5px 5px',
        // borderTop: "none"
      }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ?  '1px solid var(--theme-color)' : '1px solid #ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25)' : 'none',
      '&:hover': {
        border: '1px solid var(--theme-color)',
        boxShadow: '0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25)',
      },
      '&:focus': {
        border: '1px solid var(--theme-color)',
        boxShadow: '0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25)',
      },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'var(--theme-color)' : 'transparent',
        color: state.isSelected ? 'white' : 'black',
        '&:first-child': {
            borderRadius: '4px 4px 0 0'
        },
        '&:last-child': {
            borderRadius: '0 0 4px 4px'
        },
        '&:hover': {
            backgroundColor: state.isSelected ? 'var(--theme-color)' : 'var(--bs-gray-300)',
            color: state.isSelected ? 'white' :"black"
          },
      }),
};

export default selectStyles;