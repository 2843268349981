import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Badge, Button, ButtonGroup, Stack, ToastContainer } from 'react-bootstrap';

import { fetchObjects } from '../services/inventory';
import { setColors } from '../libs/utils';

import { AppContext } from '../contexts/AppProvider';
import { ObjectsContext } from '../contexts/ObjectsProvider';

import AppHeader from '../components/AppHeader';
import ObjectsTable from '../components/ObjectsTable';
import ObjectsGrid from '../components/ObjectsGrid';
import TableColSelector from '../components/TableColSelector';

import Icon from '../ui/Icon';
import BasicToast from '../ui/Toast';
import PageBreadcrumb from '../ui/Breadcrumb';



function ObjectsLists() {

	const { t } = useTranslation();

	const { 
		objectsViewMode, 
		setObjectsViewMode, 
		objectsViewType, 
		setObjectsViewType, 
		objectsShowCondition, 
		setObjectsShowCondition, 
		objectsShowStatus, 
		setObjectsShowStatus, 
		objectsShowCreation, 
		setObjectsShowCreation 
	} = useContext(ObjectsContext);


	const [objects, setObjects] = useState([]);
	const [currentObjects, setCurrentObjects] = useState([]);
	const [objectsCount, setObjectsCount] = useState(0);
	const [alertAction, setAlertAction] = useState("");

	const [objectTotals,setObjectTotals] = useState({
		object: 0,
		book: 0,
		clothes: 0,
		vinyl: 0
	});	

	useLayoutEffect(() => {
		setColors();
	});

	useEffect(() => {

		// Get objects list
		fetchObjects().then((response) => {
			console.log(response);
			setObjects(response.result);
			const filteredObjects = (objectsViewType !== undefined && objectsViewType !== "all") ? response.result.filter(object => object.type === objectsViewType) : response.result;
    		setCurrentObjects(filteredObjects);
			setObjectsCount(filteredObjects.length);
			let totals = {
				all: 0,
				object: 0,
				book: 0,
				clothes: 0,
				vinyl: 0
			};
			response.result.map((item) => {
				if(item.type === "object") {
					totals.object++;
				}
				if(item.type === "book") {
					totals.book++;
				}
				if(item.type === "clothes") {
					totals.clothes++;
				}
				if(item.type === "vinyl") {
					totals.vinyl++;
				}
				totals.all++;
				
			});
			setObjectTotals(totals);
			
		});

		// Last action alert
		const lastAction = localStorage.getItem("lastAction");
		if(lastAction) {
			localStorage.removeItem("lastAction");
			setAlertAction(
			(lastAction === "create") ? <BasicToast title="Objet crée !" message="L'objet à bien été crée" /> 
			: (lastAction === "edit") ? <BasicToast title="Objet modifié !" message="L'objet à bien été modifié" /> 
			: <BasicToast title="Objet supprimé !" message="L'objet à bien été supprimé" />
			)
		}

	},[]);

	useEffect(() => {
		if(objectsViewType !== "all") {
			const filteredObjects = objects.filter(object => object.type === objectsViewType);
    		setCurrentObjects(filteredObjects);
			setObjectsCount(filteredObjects.length);
		}
		else {
    		setCurrentObjects(objects);
			setObjectsCount(objects.length);
		}
		
	},[objectsViewType]);

	const handleChangeViewMode = (viewMode) => {
		console.info("Change view mode :",viewMode);
		setObjectsViewMode(viewMode);
	}

	const handleChangeViewType = (viewType) => {
		console.info("Change view type :",viewType);
		setObjectsViewType(viewType);
	}

	return (
		<>
			<div className='App'>
				<AppHeader isHome />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>{t('page_title_object_list')}</h2>
							<PageBreadcrumb />
						</div>
						<Link to="/create" className="ms-sm-auto d-none d-sm-block">
							<Button variant="primary" size="lg"><Icon name="plus" className="me-2" />{t('create_object')}</Button>
						</Link>
						<Link to="/create" className="mt-2 mb-2 d-grid d-sm-none w-100">
							<Button variant="primary" size="lg"><Icon name="plus" className="me-2" />{t('create_object')}</Button>
						</Link>
					</Stack>
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row">
						<div className="ms-0 mt-3 mt-sm-0">
							<ButtonGroup>
								<Button variant={objectsViewMode !== "table" ? 'outline-secondary' : 'secondary'} onClick={() => {handleChangeViewMode("table")}}><Icon name="list" className="me-2" />Liste</Button>
								<Button variant={objectsViewMode !== "grid" ? 'outline-secondary' : 'secondary'} onClick={() => {handleChangeViewMode("grid")}}><Icon name="grid" className="me-2" />Grille</Button>
							</ButtonGroup>
						</div>
						<div className="ms-0 ms-sm-4 mb-0 d-blocks d-sm-block text-start">
							<ButtonGroup>
								<Button variant={objectsViewType !== "all" ? 'outline-secondary' : 'secondary'} onClick={() => {handleChangeViewType('all')}}><Icon name="infinity" className="me-2" />Tout<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.all}</Badge></Button>
								{objectTotals.object ? <Button variant={objectsViewType !== "object" ? 'outline-secondary' : 'secondary'} onClick={() => {handleChangeViewType('object')}}><Icon name="rectangle-barcode" className="me-2" />Objets<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.object}</Badge></Button> : ''}
								{objectTotals.book ? <Button variant={objectsViewType !== "book" ? 'outline-secondary' : 'secondary'} onClick={() => {handleChangeViewType('book')}}><Icon name="books" className="me-2" />Livres<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.book}</Badge></Button> : ''}
								{objectTotals.clothes ? <Button variant={objectsViewType !== "clothes" ? 'outline-secondary' : 'secondary'} onClick={() => handleChangeViewType('clothes')}><Icon name="clothes-hanger" className="me-2" />Vêtements<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.clothes}</Badge></Button> : ''}
								{objectTotals.vinyl ? <Button variant={objectsViewType !== "vinyl" ? 'outline-secondary' : 'secondary'} onClick={() => handleChangeViewType('vinyl')}><Icon name="turntable" className="me-2" />Vinyls<Badge bg="dark" text="white" className="d-none d-sm-inline ms-2">{objectTotals.vinyl}</Badge></Button> : ''}
							</ButtonGroup>
						</div>
						<div className='ms-4'>
							<TableColSelector />
						</div>
					</Stack>
					{objectsViewMode === "table" && <div className="d-block d-sm-block">	
						<ObjectsTable objects={currentObjects} setObjects={setCurrentObjects} />
					</div>}
					{objectsViewMode === "grid" && <div className="d-block d-sm-block">	
						<ObjectsGrid objects={currentObjects} setObjects={setCurrentObjects} />
					</div>}
					<p className='text-center mt-3'><Badge bg="primary">{objectsCount}</Badge> objet{objectsCount > 1 && `s`} dans l'inventaire</p>
				</section>
			</div>
			<ToastContainer position="bottom-end" style={{paddingBottom: 20,paddingRight: 20}}>
				{alertAction}
			</ToastContainer>
		</>
	);
}

export default ObjectsLists;