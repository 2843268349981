import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";

import constants from '../constants.jsx';
import { setColors } from '../libs/utils';
import { AppContext } from '../contexts/AppProvider.jsx';

import Icon from '../ui/Icon.jsx';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function ObjectsGrid({ objects, setObjects, isLocation, isCategory, filter, defaultOrder }) {

    const [order, setOrder] = useState("");
    const [order2, setOrder2] = useState("");
    

    const { appTheme } = useContext(AppContext);

    const handleChangeOrder = (event) => {
		const field = event.target.dataset.field;
		const order = event.target.dataset.order ? event.target.dataset.order : "";
		setOrder(field);
		let newOrder = "";
		if(order === "" || order === "DESC") {
			event.target.dataset.order = "ASC";
			newOrder = 'ASC';
		}
		else if(order === "ASC") {
			event.target.dataset.order = "DESC";
			newOrder = "DESC";
		}
        setOrder2(newOrder);
		const newObjects = [...currentObjects];
		newObjects.sort((a, b) => {
			const nameA = a[field].toUpperCase(); // ignore upper and lowercase
			const nameB = b[field].toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return (newOrder === "ASC") ? -1 : 1;
			}
			if (nameA > nameB) {
				return (newOrder === "ASC") ? 1 : -1;
			}
			return 0;
		});
		setObjectsTabled(newObjects);
    }

    let currentObjects = objects;
    if(isCategory !== undefined) {
        let results = objects.filter((object) => {
            return (object.subcategory == filter);
        });
        currentObjects = results;
    }

    if(isLocation !== undefined) {
        let results = objects.filter((object) => {
            return (object.location_storage == filter);
        });
        currentObjects = results;
    }

    useLayoutEffect(() => {
		setColors();
	});

    const [objectsTabled, setObjectsTabled] = useState([]);

    useEffect(() => {
        setObjectsTabled(currentObjects);
    },[objects]);
    
    return (
        <>
            <Row xs={1} sm={5} className="g-4">
                {objectsTabled.map((object) =><>
						<Col key={object.id}>
							<Card bg={appTheme === "dark" && 'dark'} >
                            
								{/* <div className='text-center'>
									<Link to={`/view/${object.id}`}> */}
										<Card.Img variant="top" src={object.picture !== "" ? 'https://'+object.picture : 'holder.js/206x206'} style={{width:'100%',height:"200px",maxWidth:'100%',objectFit:"contain", backgroundColor: "white", padding:20}} />
									{/* </Link>
								</div> */}
                                {/* <Card.Header>{object.name}</Card.Header> */}
								<Card.Body>
									{/* <Card.Title style={{fontSize:'1em'}}><Link to={`/view/${object.id}`}>{object.name}</Link></Card.Title> */}
									<Card.Text style={{fontSize:'1em'}}>{object.name}</Card.Text>
									<Card.Text style={{fontSize:'1em'}}><strong>{object.brand}</strong><br/>{object.model}</Card.Text>
									<Card.Text style={{fontSize:'.9em'}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}<br/>{object.subcategory}</Link></Card.Text>
									
								</Card.Body>
                                <Card.Footer>
                                    <Link to={`/edit/${object.id}`}>
										<div className="d-grid"><Button variant="primary" size=''><Icon name="pencil" className="me-2" />Modifier</Button></div>
									</Link>	
                                </Card.Footer>
							</Card>
						</Col>
					</> )}</Row></>
    )

}

export default ObjectsGrid;