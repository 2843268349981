import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import { AppContext } from '../contexts/AppProvider.jsx';

import Icon from '../ui/Icon.jsx';

function AppHeader({isHome}) {

    const { appName, setShowedMenuApp } = useContext(AppContext);

    if(isHome) {
        return (
            <>
                <header className="App-header">
                    <h1>{appName}</h1>
                </header>
                <nav className='hamburger'>
                    <span onClick={() => setShowedMenuApp(true)}><Icon name="chevron-right" /></span>
                </nav>
            </>
        )
    }

    return (
        <>
            <header className="App-header">
                <h1><Link to="/">{appName}</Link></h1>
            </header>
            <nav className='hamburger'>
                <span onClick={() => setShowedMenuApp(true)}><Icon name="chevron-right" /></span>
            </nav>
        </>
    )

}

export default AppHeader;