import { useState, createContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ originalAppName, originalAppLang, originalAppTheme, originalAppThemeColor, originalAppThemeIconClass, children }) => {

    const [appName, setAppName] = useState(originalAppName);
    const [appLang, setAppLang] = useState(originalAppLang);
    const [appTheme, setAppTheme] = useState(originalAppTheme);
    const [appThemeColor, setAppThemeColor] = useState(originalAppThemeColor);
    const [appThemeIconClass, setAppThemeIconClass] = useState(originalAppThemeIconClass);

    const [appOriginalName, setAppOriginalName] = useState(originalAppName);
    const [appOriginalLang, setAppOriginalLang] = useState(originalAppLang);
    const [appOriginalTheme, setAppOriginalTheme] = useState(originalAppTheme);
    const [appOriginalThemeColor, setAppOriginalThemeColor] = useState(originalAppThemeColor);
    const [appOriginalThemeIconClass, setAppOriginalThemeIconClass] = useState(originalAppThemeIconClass);

    const [showedMenuApp, setShowedMenuApp] = useState(false);
 
    return (
        <AppContext.Provider value={{ 
            appName, setAppName, 
            appLang, setAppLang, 
            appTheme, setAppTheme,
            appThemeColor, setAppThemeColor,
            appThemeIconClass, setAppThemeIconClass,
            appOriginalName, setAppOriginalName, 
            appOriginalLang, setAppOriginalLang, 
            appOriginalTheme, setAppOriginalTheme,
            appOriginalThemeColor, setAppOriginalThemeColor,
            appOriginalThemeIconClass, setAppOriginalThemeIconClass,
            showedMenuApp, setShowedMenuApp
        }}>
            {children}
        </AppContext.Provider>
    )
}