import constants from '../constants.jsx';
import Color from 'color';

export const setColors = (appColor) => {
	if(appColor === undefined) {
		return true;
	}
	const color = Color(appColor);
	const systemColor = color.hex();
	let systemColorRGB = color.rgb();
	systemColorRGB = systemColorRGB.color.join(", ");
	const systemColorDarken1 = color.darken(0.15).hex();
	const systemColorDarken2 = color.darken(0.3).hex();
	const systemColorDarken3 = color.darken(0.45).hex();
	const systemColorDarken4 = color.darken(0.6).hex();
	const systemColorLighten1 = color.lighten(0.15).hex();
	const systemColorLighten2 = color.lighten(0.3).hex();
	const systemColorLighten3 = color.lighten(0.45).hex();
	const systemColorLighten4 = color.lighten(0.6).hex();
	document.querySelector(':root').style.setProperty('--theme-color', systemColor);
	document.querySelector(':root').style.setProperty('--theme-color-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--theme-color-darken1', systemColorDarken1);
	document.querySelector(':root').style.setProperty('--theme-color-darken2', systemColorDarken2);
	document.querySelector(':root').style.setProperty('--theme-color-darken3', systemColorDarken3);
	document.querySelector(':root').style.setProperty('--theme-color-darken4', systemColorDarken4);
	document.querySelector(':root').style.setProperty('--theme-color-lighten1', systemColorLighten1);
	document.querySelector(':root').style.setProperty('--theme-color-lighten2', systemColorLighten2);
	document.querySelector(':root').style.setProperty('--theme-color-lighten3', systemColorLighten3);
	document.querySelector(':root').style.setProperty('--theme-color-lighten4', systemColorLighten4);
	if(document.querySelector('.btn-primary')) {
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-primary', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-bg', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-color', 'white'); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-border-color', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-bg', systemColorDarken1); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-border-color', systemColorDarken1); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-bg', systemColorDarken2); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-border-color', systemColorDarken2); })
	}
	if(document.querySelector('.btn-outline-primary')) {
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-primary', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-bg', "transparent"); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-color', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-border-color', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-bg', systemColorDarken1); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-border-color', systemColorDarken1); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-bg', systemColorDarken2); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-border-color', systemColorDarken2); })
	}
	if(document.querySelector('.nav-link')) {
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-link-color', systemColor); })
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-pills-link-active-bg', systemColor); })
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-link-hover-color', systemColorDarken1); })
	}
		
  	return true;
};

export const getConditionData = (conditionName) => {
	if(constants.conditionOptions.filter((condition) => conditionName == condition.value).length > 0) {
		return constants.conditionOptions.filter((condition) => conditionName == condition.value).shift();
	}
	return {
		value: '',
		label: '',
		icon: '',
		text_color: ''
	};
}

export const getStatusData = (statusName) => {
	if(constants.statusOptions.filter((status) => statusName == status.value).length > 0) {
		return constants.statusOptions.filter((status) => statusName == status.value).shift();
	}
	return {
		value: '',
		label: '',
		icon: '',
		text_color: ''
	};
}

export const formatCurrency = (number) => {
	// const formatter = new Intl.NumberFormat(locale, {
	// 	style: 'currency',
	// 	currency: currency,
  
	// 	// These options are needed to round to whole numbers if that's what you want.
	// 	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	// 	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	//   });
	// return formatter.format(number);
	return number.toString().replace(".", ",") + " €";
  };