import React, { useContext } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import Icon from '../ui/Icon.jsx';

import { ObjectsContext } from '../contexts/ObjectsProvider.jsx';

function TableColSelector() {

    const { 
        objectsShowCategory, setObjectsShowCategory,
        objectsShowLocation, setObjectsShowLocation,
        objectsShowCondition, setObjectsShowCondition, 
        objectsShowStatus, setObjectsShowStatus, 
        objectsShowCreation, setObjectsShowCreation } = useContext(ObjectsContext);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Afficher les colonnes
      </Dropdown.Toggle>

      <Dropdown.Menu variant="dark" style={{width:204}}>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCategory(!objectsShowCategory)}>Catégorie{objectsShowCategory && <Icon name="check" className="ms-auto mt-1"></Icon>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowLocation(!objectsShowLocation)}>Emplacement{objectsShowLocation && <Icon name="check" className="ms-auto mt-1"></Icon>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCondition(!objectsShowCondition)}>État{objectsShowCondition && <Icon name="check" className="ms-auto mt-1"></Icon>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowStatus(!objectsShowStatus)}>Statut{objectsShowStatus && <Icon name="check" className="ms-auto mt-1"></Icon>}</Dropdown.Item>
        <Dropdown.Item className="d-flex" onClick={() => setObjectsShowCreation(!objectsShowCreation)}>Création{objectsShowCreation && <Icon name="check" className="ms-auto mt-1"></Icon>}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TableColSelector;