import { useState, createContext } from 'react';

export const ObjectsContext = createContext();

export const ObjectsProvider = ({ originalObjectsViewMode, originalObjectsViewType, children }) => {

    const [objectsViewMode, setObjectsViewMode] = useState(originalObjectsViewMode);
    const [objectsViewType, setObjectsViewType] = useState(originalObjectsViewType);

    const [objectsShowCategory, setObjectsShowCategory] = useState(true);
    const [objectsShowLocation, setObjectsShowLocation] = useState(true);
    const [objectsShowCondition, setObjectsShowCondition] = useState(false);
    const [objectsShowStatus, setObjectsShowStatus] = useState(false);
    const [objectsShowCreation, setObjectsShowCreation] = useState(false);
 
    return (
        <ObjectsContext.Provider value={{ 
            objectsViewMode, setObjectsViewMode, 
            objectsViewType, setObjectsViewType, 
            objectsShowCategory, setObjectsShowCategory,
            objectsShowLocation, setObjectsShowLocation,
            objectsShowCondition, setObjectsShowCondition, 
            objectsShowStatus, setObjectsShowStatus, 
            objectsShowCreation, setObjectsShowCreation 
        }}>
            {children}
        </ObjectsContext.Provider>
    )
}