import React, { useState, useEffect } from 'react';
import ObjectsTable from './ObjectsTable.jsx';

function StatusTable({ objects, setObjects }) {

    // const [ storages, setStorages ] = useState([]);

    // objects.forEach((object) => {
    //     if(!storages.includes(object.location_storage)) {
    //         setStorages([...storages, object.location_storage]);
    //     }
        
    // });

    // const view = storages.map((storage) => {
        return (
            // <div key={storage}>
                // <p className="lead text-theme">{storage}</p>  
                <ObjectsTable isStatus objects={objects} setObjects={setObjects} defaultOrder="status" />
            // </div>    
        )
    // });

    // return (view)

}

export default StatusTable;