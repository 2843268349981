import React, { useState, useContext, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import { Col, Row, Card, Container } from 'react-bootstrap';

import { setColors, getConditionData, getStatusData, formatCurrency } from '../libs/utils.js';

import { AppContext } from '../contexts/AppProvider';

import Icon from '../ui/Icon';


function ObjectCard({ object }) {

    const { appTheme } = useContext(AppContext);

    const pictureUrl = 'https://'+object.picture;
    const createDate = new Date(object.create_date).toLocaleDateString("fr");
    const lastUpdateDate = new Date(object.last_update_date).toLocaleDateString("fr");

    const conditionData = getConditionData(object.condition);
    const statusData = getStatusData(object.status);

    useLayoutEffect(() => {
        setColors();
    });

    return ( 
        <Card style={{ width: '100%' }} bg={appTheme === "dark" ? "dark" : ""}>
            <Card.Header>
                <Card.Title className="text-start d-flex mb-0"><div><strong>{object.brand}</strong> {object.model}</div><div class="ms-auto text-end"><span className='text-theme me-2'>{object.category}</span> {object.subcategory}</div></Card.Title>
            </Card.Header>
            <Card.Body className='px-1'>
                <div className="d-flex flex-column flex-sm-row">
                    <div className="text-center text-sm-start me-sm-3">
                        <Card.Img variant="" src={pictureUrl} style={{width:180,height:180,margin:"0 20px 0",objectFit:"contain"}} />
                    </div>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={4} className="">
                                {object.location_place && <>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><Icon name="binoculars" className="fa-fw me-2" />Emplacement</Card.Text>
                                    <Card.Text className="mb-1 text-center mb-4"><Link to={`/location/${object.location_place}`} >{object.location_place}</Link><br/>&nbsp;</Card.Text>
                                </>}
                                {object.location_storage && <>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><Icon name="box-open" className="fa-fw me-2" />Stockage</Card.Text>
                                    <Card.Text className="mb-1 text-center">{object.location_storage}</Card.Text>
                                </>}
                            </Col>
                            <Col xs={12} sm={4}>
                                {object.condition && <>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><Icon name="wine-glass-crack" className="fa-fw me-2" />État</Card.Text>
                                    <Card.Text className="mb-1 text-center mb-4"><Icon name={conditionData.icon} className="fa-fw me-2" />{conditionData.label}<br/>&nbsp;</Card.Text>
                                </>}
                                {object.status && <>
                                    <Card.Text className="mb-1 text-center text-muted fw-500"><Icon name="eye" className="fa-fw me-2" />Statut</Card.Text>
                                    <Card.Text className="mb-1 text-center mb-4"><Icon name={statusData.icon} className="fa-fw me-2" />{statusData.label}</Card.Text>
                                </>}
                                
                                    
                            </Col>
                            <Col xs={12} sm={4}>
                                {object.acquisition_type && <>
                                    <Card.Text className="mb-1 text-center text-muted fw-600"><Icon name="bags-shopping" className="fa-fw me-2" />Acquisition</Card.Text>
                                    <Card.Text className="text-center" style={{marginBottom:20}}>
                                        {object.acquisition_type === "purchase" && (<><Icon name="shopping-cart" className="me-2 fa-fw" /><span>{object.acquisition_shop}</span><br/><small>{new Date(object.acquisition_date).toLocaleDateString("fr")}</small></>)}
                                        {object.acquisition_type === "gift" && (<><Icon name="gift" className="me-2" /><span>Offert par {object.acquisition_giver}</span></>)}
                                    </Card.Text>
                                </>}
                                {object.initial_value && <>
                                    <Card.Text className="mb-1 text-center text-muted fw-600"><Icon name="money-bill" className="fa-fw me-2" />Valeur</Card.Text>
                                    <Card.Text className="mb-1 text-center mb-0">
                                        {object.actual_value && (<span>{formatCurrency(object.actual_value)}</span>)}{object.initial_value && (<small><br/>Initiale : {formatCurrency(object.initial_value)}</small>)}
                                        {/* {object.acquisition_type === "gift" && (<><Icon name="gift" className="me-2" /><span>Offert par {object.acquisition_giver}</span></>)} */}
                                    </Card.Text>
                                </>}
                            </Col>
                            {/* <Col xs={12} sm={3}>
                                <Card.Text className="mb-1 text-center text-muted"><Icon name="files" className="fa-fw me-2" />Fichiers</Card.Text>
                                
                                
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col xs={12} sm={4} className="text-start text-muted">
                        Crée le {createDate}
                    </Col>
                    <Col xs={12} sm={4} className="text-center d-flex justify-content-around">
                        {object.manual && <Card.Text className="mb-0"><a href={`https://${object.manual}`} target="_blank" rel="noreferrer" className='m-auto'><Icon name="book-open-cover" className="me-2" />Manuel</a></Card.Text>}
                        {object.acquisition_invoice && <Card.Text className="mb-0"><a href={`https://${object.acquisition_invoice}`} target="_blank" rel="noreferrer" className='m-auto'><Icon name="file-invoice-dollar" className="me-2" />Facture</a></Card.Text>}
                    </Col>
                    <Col xs={12} sm={4} className="text-end text-muted">
                        Modifié le {lastUpdateDate}
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )

}

export default ObjectCard;