import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; 

import { updateApplication } from '../services/application';
import { setColors } from '../libs/utils';

import { AppContext } from '../contexts/AppProvider.jsx';

function AppWrapper({children}) {

    const { t, i18n } = useTranslation();

    const { 
        appLang, appOriginalLang, setAppOriginalLang, 
        appName, appOriginalName, setAppOriginalName, 
        appTheme, appOriginalTheme, setAppOriginalTheme, 
        appThemeColor, appOriginalThemeColor, setAppOriginalThemeColor, 
        appThemeIconClass, appOriginalThemeIconClass, setAppOriginalThemeIconClass, 
    } = useContext(AppContext);


    useEffect(() => {
        if(appName !== appOriginalName) {
            const appData = {
                application_name: appName
            }
            updateApplication(appData).then((response) => {
                if(response.status === "Updated") {
                    setAppOriginalName(appName);
				}
            })
        }
    },[appName]);

    useEffect(() => {
        i18n.changeLanguage(appLang);
        localStorage.setItem('appLang', appLang);
        if(appLang !== appOriginalLang) {
            const appData = {
                application_locale: appLang
            }
            updateApplication(appData).then((response) => {
                if(response.status === "Updated") {
                    setAppOriginalLang(appLang);
				}
            })
        }
    },[appLang]);

    useEffect(() => {
        if(appTheme !== appOriginalTheme) {
            if(appTheme === "dark") {
                document.body.classList.remove('theme-light');
                document.body.classList.add('theme-dark');
                localStorage.setItem('theme','dark');
            }
            else {
                document.body.classList.remove('theme-dark');
                document.body.classList.add('theme-light');
                localStorage.setItem('theme','light');
            }
            const appData = {
                application_theme: appTheme
            }
            updateApplication(appData).then((response) => {
                if(response.status === "Updated") {
                    setAppOriginalTheme(appTheme);
				}
            })
        }
    },[appTheme]);

    useEffect(() => {
        if(appThemeColor !== undefined) {
            if(appThemeColor !== appOriginalThemeColor) {
                const appData = {
                    application_theme_color: appThemeColor
                }
                updateApplication(appData).then((response) => {
                    if(response.status === "Updated") {
                        setAppOriginalThemeColor(appThemeColor);
                        setColors(appThemeColor);
                    }
                })
            }
            else {
                setColors(appThemeColor);
            }
        }
    },[appThemeColor]);

    useEffect(() => {
        if(appThemeIconClass !== appOriginalThemeIconClass) {
            const appData = {
                application_theme_icon_class: appThemeIconClass
            }
            updateApplication(appData).then((response) => {
                if(response.status === "Updated") {
                    setAppOriginalThemeIconClass(appThemeIconClass);
				}
            })
        }
    },[appThemeIconClass]);

    return(
        <div className={`App-wrapper theme-${appTheme}`}>
            {children}
        </div>
    );
}

export default AppWrapper;