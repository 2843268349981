import React, { useState, useEffect, useLayoutEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Select, { components }  from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue, CustomMultiValue } from '../ui/selectComponents';

const washingInstructionOptions = [
    { value: 'washing-not-allowed', label: 'Ne pas laver', washicon: 'washing-not-allowed' },
    { value: 'washing-hand', label: 'Lavage à la main', washicon: 'washing-hand' },
    { value: 'washing', label: 'Lavage à la machine, normal', washicon: 'washing' },
   
    { value: 'washing-30deg', label: 'Lavage à la machine, à froid', washicon: 'washing-30deg' },
    { value: 'washing-40deg', label: 'Lavage à la machine, à 40°', washicon: 'washing-40deg' },
    { value: 'washing-50deg', label: 'Lavage à la machine, à 50°', washicon: 'washing-50deg' },
    { value: 'washing-60deg', label: 'Lavage à la machine, à 60°', washicon: 'washing-60deg' },
    { value: 'washing-90deg', label: 'Lavage à la machine, à 70°', washicon: 'washing-70deg' },
    { value: 'washing-90deg', label: 'Lavage à la machine, à 90°', washicon: 'washing-90deg' },
    
];



function ObjectDataFormGroup({objectType, objectDatas, setObjectDatas}) {

    if(objectType === "book") {
        return (
            <><Form.Group className="mb-3">
                <Form.Label>Auteur du livre</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Entrez l'auteur du livre" 
                    value={objectType === "book" && objectDatas.author ? objectDatas.author : ""} 
                    onChange={(event) => {
                        if(objectType === "book") {
                            setObjectDatas({...objectDatas,author:event.target.value});
                        }
                    }}
            />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Éditeur du livre</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Entrez l'éditeur du livre" 
                    value={objectType === "book" && objectDatas.editor ? objectDatas.editor : ""} 
                    onChange={(event) => {
                        if(objectType === "book") {
                            setObjectDatas({...objectDatas,editor:event.target.value});
                        }
                    }}
            />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Collection du livre</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Entrez la collection du livre" 
                    value={objectType === "book" && objectDatas.collection ? objectDatas.collection : ""} 
                    onChange={(event) => {
                        if(objectType === "book") {
                            setObjectDatas({...objectDatas,collection:event.target.value});
                        }
                    }}
            />
            </Form.Group>
            </>
        )
    }
    if(objectType === "clothes") {
        return (
            <>
                <Form.Group className="mb-3">
                    <Form.Label>Marque du vêtement</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez la marque du vêtement" 
                        value={objectType === "clothes" && objectDatas.brand ? objectDatas.brand : ""} 
                        onChange={(event) => {
                            if(objectType === "clothes") {
                                // setObjectData1(event.target.value)
                                setObjectDatas({...objectDatas,brand:event.target.value});
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Taille du vêtement</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez la taille du vêtement" 
                        value={objectType === "clothes" && objectDatas.size ? objectDatas.size : ""} 
                        onChange={(event) => {
                            if(objectType === "clothes") {
                                setObjectDatas({...objectDatas,size:event.target.value});
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Instruction de lavage du vêtement</Form.Label>
                    <Select isMulti
                        placeholder="Sélectionnez les instructions de lavage du vêtement" 
                        options={washingInstructionOptions} 
                        value={objectType === "clothes" && objectDatas.wash_instructions ? objectDatas.wash_instructions : []} 
                        // onChange={selectOptions}
                        onChange={(options) => {
                            // console.log(options);
                            // const result = [];
                            // options.forEach((option) => {
                            //     result.push(option.value);
                            // });
                            if(objectType === "clothes") {
                                // setObjectData2(options);
                                setObjectDatas({...objectDatas,wash_instructions:options});
                            }
                            // console.log(result);

                        }}
                        styles={selectStyles}
                        components={{Option: CustomOption, SingleValue:CustomSingleValue, MultiValue:CustomMultiValue }}
                    />
                </Form.Group>
            </>
        )
    }
    if(objectType === "vinyl") {
        return (
            <Form.Group className="mb-3">
                <Form.Label>Artiste du vinyl</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Entrez l'artiste du vinyl" 
                    value={objectType === "vinyl" && objectDatas.artist ? objectDatas.artist : ""} 
                    onChange={(event) => {
                        if(objectType === "vinyl") {
                            // setObjectData1(event.target.value);
                            setObjectDatas({...objectDatas,artist:event.target.value});
                        }
                    }}
            />
            </Form.Group>
        )
    }
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Marque de l'objet</Form.Label>
                <Form.Control 
                    // id="brand-typeahead"
                    // allowNew
                    type="text"
                    placeholder="Entrez la marque de l'objet" 
                    value={objectType === "object" && objectDatas.brand ? objectDatas.brand : ""} 
                    // options={objectBrandList}
                    onChange={(event) => {
                        if(objectType === "object") {
                            // setObjectData1(event.target.value);
                            setObjectDatas({...objectDatas,brand:event.target.value});
                        }
                    }}									
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Modèle de l'objet</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Entrez le modèle de l'objet" 
                    value={objectType === "object" && objectDatas.model ? objectDatas.model : ""} 
                    onChange={(event) => {
                        if(objectType === "object") {
                            // setObjectData2(event.target.value);
                            setObjectDatas({...objectDatas,model:event.target.value});
                        }
                    }}
            />
            </Form.Group>
        </>
    )
}

export default ObjectDataFormGroup;