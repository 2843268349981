const constants = {
    conditions: {
        new: "Neuf",
        good: "Bon état",
        correct: "État correct",
        damaged: "Endommagé",
        hs: "Inutilisable"
    },
    conditionsOrder: {
        new: 0,
        good: 1,
        correct: 2,
        damaged: 3,
        hs: 4
    },
    status: {
        to_keep: "À conserver",
        to_repair: "À réparer",
        to_sell: "À revendre",
        to_give: "À donner",
        to_trash: "À jeter",
    },
    statusKey: {
        "À conserver": "to_keep",
        "À réparer": "to_repair",
        "À revendre": "to_sell",
        "À donner": "to_give",
        "À jeter": "to_trash",
    },
    statusOrder: {
        to_keep: 0,
        to_repair: 1,
        to_sell: 2,
        to_give: 3,
        to_trash: 4,
    },
    conditionOptions: [
		{ value: 'new', label: 'Neuf', icon: 'cash-register', text_color: 'green' },
		{ value: 'good', label: 'Bon état', icon: 'thumbs-up', text_color: 'blue' },
		{ value: 'correct', label: 'État correct', icon: 'check', text_color: 'purple' },
		{ value: 'damaged', label: 'Endommagé', icon: 'thumbs-down', text_color: 'orange' },
		{ value: 'hs', label: 'Inutilisable', icon: 'heart-crack', text_color: 'red' },
	],
    statusOptions: [
		{ value: 'to_keep', label: 'A conserver', icon: 'box-check' },
		{ value: 'to_repair', label: 'A réparer', icon: 'screwdriver' },
		{ value: 'to_sell', label: 'A revendre', icon: 'sack-dollar' },
		{ value: 'to_give', label: 'A donner', icon: 'hand-holding-heart' },
		{ value: 'to_trash', label: 'A jeter', icon: 'trash' },
	],
    conditionsColors: {
        new: "green",
        good: "blue",
        correct: "yellow",
        damaged: "orange",
        hs: "red"
    },
    conditionsIndex: {
        new: 0,
        good: 1,
        correct: 2,
        damaged: 3,
        hs: 4
    },
    propertiesLabel: {
        condition: "État",
        category: "Catégorie",
        subcategory: "Sous-catégorie",
        name: "Nom",
        actual_value: "Valeur actuelle",
        location_place: "Emplacement",
        location_storage: "Stockage",
        picture: "Image",
        object: "Objet",
        acquisition_type: "Type d'acquisition",
        acquisition_date: "Date d'acquisition",
        acquisition_giver: "Donneur",
        notes: "Notes",
        type: "Type"
    },
    typesName: {
        object: "🛒 Objet",
        book: "📚 Livre",
        clothes: "🧥 Vêtement",
        vinyl: "💿 Vinyl"
    },
    typesLabel: {
        object: "de l'objet",
        book: "du livre",
        clothes: "du vêtement",
        vinyl: "du vinyl"
    }
}

export default constants;