import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

import { askPassport, checkApplication } from './services/application';

import { AppProvider } from './contexts/AppProvider';
import { ObjectsProvider } from './contexts/ObjectsProvider';

import ObjectsList from './pages/ObjectsList';
import ObjectForm from './pages/ObjectForm';
import ObjectView from './pages/ObjectView'
import StatusView from './pages/StatusView';
import ConditionView from './pages/ConditionView';
import LocationView from './pages/LocationView';
import CategoryView from './pages/CategoryView';
import AppUI from './pages/AppUI';

import MenuApp from './menus/MenuApp';

import AppWrapper from './components/AppWrapper';

import './i18n';
import "./index.scss";

function App() {

    const [ isLoading, setIsLoading ] = useState(true);

    const [ originalAppName, setOriginalAppName ] = useState("");
    const [ originalAppLang, setOriginalAppLang ] = useState("fr_FR");
    const [ originalAppTheme, setOriginalAppTheme ] = useState("dark");
    const [ originalAppThemeColor, setOriginalAppThemeColor ] = useState("#03a9f4");
    const [ originalAppThemeIconClass, setOriginalAppThemeIconClass ] = useState("solid");

    useEffect(() => {
        askPassport().then(() => {
            checkApplication().then((response) => {
                const application = response.result;
                setOriginalAppName(application.name);
                setOriginalAppLang(application.locale);
                setOriginalAppTheme((application.theme !== undefined) ? application.theme.theme : 'dark');
                setOriginalAppThemeColor((application.theme !== undefined) ? application.theme.color : (application.system !== undefined) ? application.system.color_hex : '#03a9f4');
                setOriginalAppThemeIconClass((application.theme !== undefined) ? application.theme.icon_class : 'solid');
                setIsLoading(false);
            });
        });
    },[]);

    if(isLoading) {
        return (
            <div className="App-loader">
                <Spinner animation="border" />
                <p className="mt-2">Chargement ...</p>
            </div>
        )
    }

    return (
        <AppProvider 
            originalAppName={originalAppName} 
            originalAppLang={originalAppLang}
            originalAppTheme={originalAppTheme}
            originalAppThemeColor={originalAppThemeColor}
            originalAppThemeIconClass={originalAppThemeIconClass}
        >
            <ObjectsProvider
                originalObjectsViewMode="table" 
                originalObjectsViewType="object" 
            >
                <AppWrapper>
                    <BrowserRouter>    
                        <Routes>
                            <Route path="/" element={<ObjectsList />} />
                            <Route path="create" element={<ObjectForm mode="add" />} />
                            <Route path="edit/:id" element={<ObjectForm mode="edit" />} />
                            <Route path="view/:id" element={<ObjectView />} />
                            <Route path="status/:name" element={<StatusView />} />
                            <Route path="condition/:name" element={<ConditionView />} />
                            <Route path="location/:name" element={<LocationView />} />
                            <Route path="category/:name" element={<CategoryView />} />
                            <Route path="ui" element={<AppUI />} />
                        </Routes>
                    </BrowserRouter>
                    <MenuApp />
                </AppWrapper>
            </ObjectsProvider>
        </AppProvider>
    );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);