import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue } from '../ui/selectComponents';
import { CirclePicker } from 'react-color';
import { Offcanvas, Form } from 'react-bootstrap';

import { AppContext } from '../contexts/AppProvider.jsx';

function MenuApp() {

    const { 
        appName, setAppName,
        appLang, setAppLang,
        appTheme, setAppTheme,
        appThemeColor, setAppThemeColor,
        appThemeIconClass, setAppThemeIconClass,
        showedMenuApp, setShowedMenuApp
    } = useContext(AppContext);

    const langOptions = [
        { value: 'fr_FR', label: 'Français', flag: '🇫🇷' },
        { value: 'en_US', label: 'English', flag: '🇬🇧' },
    ];

    const iconClassOptions = [
        { value: 'solid', label: 'Solid', icon: 'fort', iconClass: 'solid' },
        { value: 'regular', label: 'Regular', icon: 'fort', iconClass: 'regular' },
        { value: 'light', label: 'Light', icon: 'fort', iconClass: 'light' },
        { value: 'thin', label: 'Thin', icon: 'fort', iconClass: 'thin' },
        { value: 'duotone', label: 'Duotone', icon: 'fort', iconClass: 'duotone' },
    ];

    const colors = ["#f44336", "#d01c1f", "#e91e63", "#cd2c70", "#9c27b0", "#673ab7", "#3f51b5", "#4a80be", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#39a744", "#8bc34a", "#c1db3c", "#cddc39", "#b7b079","#ffeb3b", "#f6d003", "#ffc107", "#ff9800", "#fe7e03", "#ff5722", "#795548", "#ab8266",'#fa9a84','#fdc2c6','#d4c9cd','#f4d7c5','#c8e0e0','#a0c3d9',"#607d8b"];

    const [selectedLang, setSelectedLang] = useState({});
    const [selectedIconClass, setSelectedIconClass] = useState({});

    useEffect(() => {
        setSelectedLang(langOptions.filter((lang) => ( lang.value === appLang)).shift());
    },[appLang]);

    useEffect(() => {
        setSelectedIconClass(iconClassOptions.filter((iconClass) => ( iconClass.value === appThemeIconClass)).shift());
    },[appThemeIconClass]);

    const selectLang = (option) => {
		    setSelectedLang(option);
		    setAppLang(option.value);
	  }

    const selectIconClass = (option) => {
		    setSelectedIconClass(option);
		    setAppThemeIconClass(option.value);
	  }

  
    return (
        <Offcanvas show={showedMenuApp} onHide={() => setShowedMenuApp(false)} className={appTheme === "dark" ? 'bg-black text-white' : 'bg-white text-black'}>
            <Offcanvas.Header closeButton closeVariant={appTheme === "dark" ? "white" : ""}>
                <Offcanvas.Title>Application</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form.Label htmlFor="app-name">Nom de l'applcation</Form.Label>
                <Form.Control 
                    type="text"
                    id="app-name"
                    value={appName}
                    onChange={(event) => {
                        setAppName(event.target.value);
                    }}
                />
                <Form.Label htmlFor="theme-color" className='mt-3'>Langue</Form.Label>
                <Select 
                    className="mb-4" isSearchable={false}
                    options={langOptions} 
                    value={selectedLang}
                    onChange={selectLang}
                    styles={selectStyles}
                    components={{Option: CustomOption, SingleValue:CustomSingleValue}} 
                />
                <Form.Check 
                    type="switch"
                    id="custom-switch"
                    label="Activer le mode Sombre"
                    onChange={() => setAppTheme(() => appTheme === 'dark' ? 'light' : 'dark') }
                    checked={appTheme === "dark"}
                />
                <Form.Label htmlFor="theme-color" className='mt-3'>Couleur du thème</Form.Label>
                <CirclePicker
                    color={appThemeColor}
                    colors={colors}
                    onChange={(color) => {
                      setAppThemeColor(color.hex);
                    }}
                />
                <Form.Label htmlFor="theme-color" className='mt-3'>Type d'icône</Form.Label>

                <Select 
                    isSearchable={false} menuPlacement="auto"
                    options={iconClassOptions} 
                    value={selectedIconClass}
                    onChange={selectIconClass}
                    styles={selectStyles}
                    components={{Option: CustomOption, SingleValue:CustomSingleValue}} 
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuApp;