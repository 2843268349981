import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import MDEditor from '@uiw/react-md-editor';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import Select, { components }  from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOption, CustomSingleValue } from '../ui/selectComponents';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { InputTags } from 'react-bootstrap-tagsinput';

import { 
	createObject, fetchObject, editObject, deleteObject, 
	fetchCategories, fetchSubcategories, fetchNames, fetchLocationPlace, fetchLocationStorage } from '../services/inventory';
	import { uploadFile, deleteFile } from '../services/cloud';
import { setColors } from '../libs/utils';

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

import AppHeader from '../components/AppHeader.jsx';
import ObjectDataFormGroup from '../components/ObjectDataFormGroup.jsx';

import { AppContext } from '../contexts/AppProvider';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import constants from '../constants';
import PageBreadcrumb from '../ui/Breadcrumb';
import Icon from '../ui/Icon';
import BasicToast from '../ui/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function ObjectForm({mode}) {

	const params = useParams();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const [ objectID, setObjectID ] = useState("");
	const [ object, setObject ] = useState("");
	const [ objectName, setObjectName ] = useState([]);
	const [ objectNameList, setObjectNameList ] = useState([]);
	const [ objectNameListData, setObjectNameListData ] = useState([]);
	const [ objectCat, setObjectCat ] = useState([]);
	const [ objectCategoryList, setObjectCategoryList ] = useState([]);
	const [ objectCategoryListData, setObjectCategoryListData ] = useState([]);
	const [ objectSubcat, setObjectSubcat ] = useState([]);
	const [ objectSubcategoryList, setObjectSubcategoryList ] = useState([]);
	const [ objectSubcategoryListData, setObjectSubcategoryListData ] = useState([]);
	const [ objectType, setObjectType ] = useState("object");
	const [ objectLocationPlace, setObjectLocationPlace ] = useState([]);
	const [ objectLocationPlaceList, setObjectLocationPlaceList ] = useState([]);
	const [ objectLocationStorage, setObjectLocationStorage ] = useState([]);
	const [ objectLocationStorageList, setObjectLocationStorageList ] = useState([]);
	const [ objectLocationStorageListData, setObjectLocationStorageListData ] = useState([]);
	const [ objectCondition, setObjectCondition ] = useState("");
	const [ objectStatus, setObjectStatus ] = useState("");
	const [ objectAcquisitionDate, setObjectAcquisitionDate ] = useState("");
	const [ objectAcquisitionType, setObjectAcquisitionType ] = useState("");
	const [ objectAcquisitionShop, setObjectAcquisitionShop ] = useState("");
	const [ objectAcquisitionGiver, setObjectAcquisitionGiver ] = useState("");
	const [ objectAcquisitionInvoice, setObjectAcquisitionInvoice ] = useState("");
	const [ objectManual, setObjectManual ] = useState("");
	const [ objectPicture, setObjectPicture ] = useState("");
	const [ objectNotes, setObjectNotes ] = useState("");
	const [ objectInitialValue, setObjectInitialValue ] = useState(0);
	const [ objectActualValue, setObjectActualValue ] = useState(0);
	const [ objectUpdates, setObjectUpdates ] = useState([]);
	const [ objectDatas, setObjectDatas ] = useState({});
	const [ objectIsMultiple, setObjectIsMultiple ] = useState(false);
	const [ objectQuantity, setObjectQuantity ] = useState(1);

	const [ lastAction, setLastAction ] = useState("");

	const { appLang, appTheme } = useContext(AppContext);

	const title = mode === "add" ?  t('create_object') : t('edit_object');

	const typeOptions = [
		{ value: 'object', label: 'Objet', icon: 'rectangle-barcode' },
		{ value: 'book', label: 'Livre', icon: 'books' },
		{ value: 'clothes', label: 'Vêtement', icon: 'clothes-hanger' },
		{ value: 'vinyl', label: 'Vinyl', icon: 'turntable' },
	];

	const conditionOptions = [
		{ value: 'new', label: 'Neuf', icon: 'cash-register', text_color: 'green' },
		{ value: 'good', label: 'Bon état', icon: 'thumbs-up', text_color: 'blue' },
		{ value: 'correct', label: 'État correct', icon: 'check', text_color: 'purple' },
		{ value: 'damaged', label: 'Endommagé', icon: 'thumbs-down', text_color: 'orange' },
		{ value: 'hs', label: 'Inutilisable', icon: 'heart-crack', text_color: 'red' },
	];

	const statusOptions = [
		{ value: 'to_keep', label: 'A conserver', icon: 'house' },
		{ value: 'to_repair', label: 'A réparer', icon: 'screwdriver' },
		{ value: 'to_sell', label: 'A revendre', icon: 'sack-dollar' },
		{ value: 'to_give', label: 'A donner', icon: 'hand-holding-heart' },
		{ value: 'to_trash', label: 'A jeter', icon: 'trash' },
	];

	const acquisitionTypeOptions = [
		{ value: 'purchase', label: 'Achat', icon: 'basket-shopping' },
		{ value: 'gift', label: 'Cadeau', icon: 'gift' },
	];

	const washingInstructionOptions = [
		{ value: 'washing', label: 'Lavage à la machine, normal', washicon: 'washing' },
		{ value: 'washing-hand', label: 'Lavage à la main', washicon: 'washing-hand' },
		{ value: 'washing-30deg', label: 'Lavage à la machine, à froid', washicon: 'washing-30deg' },
		{ value: 'washing-not-allowed', label: 'Ne pas laver', washicon: 'washing-not-allowed' },
	];

	const [selectedType, setSelectedType] = useState(typeOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedCondition, setSelectedCondition] = useState(null);
	const [selectedAcquisitionType, setSelectedAcquisitionType] = useState(null);

	useLayoutEffect(() => {
	  setColors();
	});
	
	useEffect(() => {
		if(params.id) {
			setObjectID(params.id);
			fetchObject(params.id).then((response) => {
				const item = response.result;
				setObjectName([item.name]);
				setObjectCat([item.category])
				setObjectSubcat([item.subcategory])
				setObjectPicture(item.picture);
				setObjectType(item.type);
				setSelectedType(typeOptions[typeOptions.findIndex(p => p.value == item.type)]);
				switch (item.type) {
					case "object":
						setObjectDatas({
							brand: item.brand ? item.brand : "",
							model: item.model ? item.model : "",
						});
						break;
					case "book":
						setObjectDatas({
							author: item.author ? item.author : "",
							editor: item.editor ? item.editor : "",
							collection: item.collection ? item.collection : "",
						});
						break;
					case "clothes":
						const wash_instructions = [];
						item.wash_instructions.split(",").forEach((value) => {
							wash_instructions.push(washingInstructionOptions[washingInstructionOptions.findIndex(p => p.value == value)]);
						});
						setObjectDatas({
							brand: item.brand ? item.brand : "",
							size: item.size ? item.size : "",
							wash_instructions: wash_instructions
						});
						break;
					case "vinyl":
						setObjectDatas({
							artist: item.artist ? item.artist : "",
						});
						break;
					default:
						break;
				}
				setObjectLocationPlace([item.location_place]);
				setObjectLocationStorage([item.location_storage]);
				setObjectCondition(item.condition);
				setSelectedCondition(conditionOptions[conditionOptions.findIndex(p => p.value == item.condition)]);
				setObjectStatus(item.status);
				setSelectedStatus(statusOptions[statusOptions.findIndex(p => p.value == item.status)]);
				setObjectAcquisitionDate(item.acquisition_date.split("-").reverse().join("/"));
				setObjectAcquisitionType(item.acquisition_type);
				setSelectedAcquisitionType(acquisitionTypeOptions[acquisitionTypeOptions.findIndex(p => p.value == item.acquisition_type)]);
				setObjectAcquisitionShop(item.acquisition_shop);
				setObjectAcquisitionGiver(item.acquisition_giver);
				setObjectAcquisitionInvoice(item.acquisition_invoice);
				setObjectManual(item.manual);
				setObjectInitialValue(item.initial_value);
				setObjectActualValue(item.actual_value);
				setObjectNotes(item.notes);
				setObjectUpdates(item.updates);
				setObjectIsMultiple(item.is_multiple === '0' ? false : true);
				setObjectQuantity(item.quantity ? item.quantity : 1);
				setObject(item);
			});
			
		};
		
	},[]);

	fetchCategories().then((response) => {
		setObjectCategoryListData(response.result);
		let categories = [];
		response.result.forEach((result) => {
			if(result.type === objectType)
			categories.push(result.name);
		});
		setObjectCategoryList(categories);
	});
	fetchSubcategories().then((response) => {
		setObjectSubcategoryListData(response.result);
		let subcategories = [];
		response.result.forEach((result) => {
			if(result.category == objectCat[0])
			subcategories.push(result.name);
		});
		setObjectSubcategoryList(subcategories);
	});
	fetchNames().then((response) => {
		setObjectNameListData(response.result);
		let names = [];
		response.result.forEach((result) => {
			if(result.type === objectType)
			names.push(result.name);
		});
		setObjectNameList(names);
	});
	fetchLocationPlace().then((response) => {
		setObjectLocationPlaceList(response.result);
	});
	fetchLocationStorage().then((response) => {
		setObjectLocationStorageListData(response.result);
		let locations = [];
		response.result.forEach((result) => {
			if(result.location_place == objectLocationPlace[0])
			locations.push(result.location_storage);
		});
		console.log(locations);
		setObjectLocationStorageList(locations);
	});


	useEffect(() => {
		let categories = [];
		objectCategoryListData.forEach((result) => {
			if(result.type === objectType)
			categories.push(result.name);
		});
		setObjectCategoryList(categories);
		let names = [];
		objectNameListData.forEach((result) => {
			if(result.type === objectType)
			names.push(result.name);
		});
		setObjectNameList(names);
	},[objectType]);

	useEffect(() => {
		let subcategories = [];
		objectSubcategoryListData.forEach((result) => {
			if(result.category == objectCat[0])
			subcategories.push(result.name);
		});
		setObjectSubcategoryList(subcategories);
	},[objectCat]);

	useEffect(() => {
		let locations = [];
		objectLocationStorageListData.forEach((result) => {
			if(result.location_place == objectLocationPlace[0])
			locations.push(result.location_storage);
		});
		setObjectLocationStorageList(locations);
	},[objectLocationPlace]);

	const handleSubmit = (event) => {
		event.preventDefault();
		setLastAction("");
		let category = objectCat;
		if(typeof objectCat[0] === 'object') {
			if(objectCat[0].customOption) {
				category = [objectCat[0].label];
			}
		}
		let subcategory = objectSubcat;
		if(typeof objectSubcat[0] === 'object') {
			if(objectSubcat[0].customOption) {
				subcategory = [objectSubcat[0].label];
			}
		}
		let name = objectName;
		if(typeof objectName[0] === 'object') {
			if(objectName[0].customOption) {
				name = [objectName[0].label];
			}
		}
		let locationPlace = objectLocationPlace;
		if(typeof objectLocationPlace[0] === 'object') {
			if(objectLocationPlace[0].customOption) {
				locationPlace = [objectLocationPlace[0].label];
			}
		}
		let locationStorage = objectLocationStorage;
		if(typeof objectLocationStorage[0] === 'object') {
			if(objectLocationStorage[0].customOption) {
				locationStorage = [objectLocationStorage[0].label];
			}
		}
		let objectDatasSend = [];
		switch (objectType) {
			case "object":
				objectDatasSend = [
					{
						data_name: 'brand',
						data_value: objectDatas.brand
					},
					{
						data_name: 'model',
						data_value: objectDatas.model
					}
				];
				break;
			case "book":
				objectDatasSend = [
					{
						data_name: 'author',
						data_value: objectDatas.author
					},
					{
						data_name: 'editor',
						data_value: objectDatas.editor
					},
					{
						data_name: 'collection',
						data_value: objectDatas.collection
					}
				];
				break;
			case "clothes":
				const washInstructions =[];
				objectDatas.wash_instructions.forEach((option) => {
					washInstructions.push(option.value);
				});
				objectDatasSend = [
					{
						data_name: 'brand',
						data_value: objectDatas.brand
					},
					{
						data_name: 'size',
						data_value: objectDatas.size
					},
					{
						data_name: 'wash_instructions',
						data_value: washInstructions.join(",")
					}
				];
				break;
			case "vinyl":
				objectDatasSend = [
					{
						data_name: 'artist',
						data_value: objectDatas.artist
					}
				];
				break;
			default:
				break;
		}
		const objectData = { 
			object_name:name[0], 
			object_category:category[0], 
			object_subcategory:subcategory[0],
			object_type:objectType, 
			object_location_place: locationPlace[0],
			object_location_storage: locationStorage[0],
			object_condition: objectCondition, 
			object_status: objectStatus, 
			object_initial_value: objectInitialValue,
			object_actual_value: objectActualValue,
			object_acquisition_date: objectAcquisitionDate,
			object_acquisition_type: objectAcquisitionType,
			object_acquisition_shop: objectAcquisitionShop,
			object_acquisition_giver: objectAcquisitionGiver,
			object_acquisition_invoice: objectAcquisitionInvoice,
			object_manual: objectManual,
			object_picture: objectPicture, 
			object_notes: objectNotes,
			object_datas: objectDatasSend, 
			object_is_multiple: objectIsMultiple ? '1' : '0',
			object_quantity: objectQuantity
		};
		
		if(mode === "add") {
			createObject(objectData).then((response) => {
				if(response.status == "Created") {
					localStorage.setItem("lastAction", "create");
					navigate('/');
				}
			});
		}
		
		if(mode === "edit") {
			editObject(objectID,objectData).then((response) => {
				if(response.status === "Updated") {
					setLastAction("edit");
					// localStorage.setItem("lastAction", "edit");
					// navigate('/');
				}
			});
			
		}
	}

	const handleDelete = (event) => {
		event.preventDefault();
		deleteObject(objectID).then((response) => {
			if(response.status === "Deleted") {
				localStorage.setItem("lastAction", "delete");
				navigate('/');
			}
		});
	}

	const handlePictureUpload = (event) => {
		event.preventDefault();
		let fileName = "picture_"+objectName;
		fileName = fileName.replaceAll(" ","-").toLowerCase();
		const filePath = "inventory/pictures/";
		const fileData = new FormData();
		fileData.append("file",event.target.files[0]);
		fileData.append("file_name",fileName);
		fileData.append("file_path",filePath);
		uploadFile(fileData).then((response) => {
			const fileURL = response.result.url;
			setObjectPicture(fileURL);
		});
	}

	const handlePictureDelete = (event) => {
		event.preventDefault();
		deleteFile(objectPicture).then((response) => {
			setObjectPicture("");
		});
	}

	const handleInvoiceUpload = (event) => {
		event.preventDefault();
		let fileName = "invoice_"+objectName;
		fileName = fileName.replaceAll(" ","-").toLowerCase();
		const filePath = "inventory/invoices/";
		const fileData = new FormData();
		fileData.append("file",event.target.files[0]);
		fileData.append("file_name",fileName);
		fileData.append("file_path",filePath);

		uploadFile(fileData).then((response) => {
			const fileURL = response.result.url;
			setObjectAcquisitionInvoice(fileURL);
		});
	}

	const handleInvoiceDelete = (event) => {
		event.preventDefault();
		deleteFile(objectAcquisitionInvoice).then((response) => {
			setObjectAcquisitionInvoice("");
		});
	}

	const handleManualUpload = (event) => {
		event.preventDefault();

		let fileName = "manual_"+objectName;
		fileName = fileName.replaceAll(" ","-").toLowerCase();
		const filePath = "inventory/manuals/";

		const fileData = new FormData();
		fileData.append("file",event.target.files[0]);
		fileData.append("file_name",fileName);
		fileData.append("file_path",filePath);

		uploadFile(fileData).then((response) => {
			const fileURL = response.result.url;
			setObjectManual(fileURL);
		});
	}

	const handleManualDelete = (event) => {
		event.preventDefault();
		deleteFile(objectManual).then((response) => {
			setObjectManual("");
		});
	}

	const customStyles = {
		option: (provided, {data}) => ({
		  ...provided,
		  color: data.color,
		}),
		control: (base, state) => ({
            ...base,
            '&:hover': { borderColor: 'gray' }, // border style on hover
            '&:focus': { borderColor: 'red' }, // border style on hover
            border: '1px solid lightgray', // default border color
            boxShadow: 'none', // no box-shadow
        }),
	  }

	let createDate;
	let lastUpdateDate;
	if(mode === "edit") {
		createDate = new Date(object.create_date).toLocaleString(appLang.substr(0,2));
    	lastUpdateDate = new Date(object.last_update_date).toLocaleString(appLang.substr(0,2));
	}
	
	const selectOptions = (option) => {
		setSelectedType(option);
		setObjectType(option.value);
	}

	const selectStatus = (option) => {
		setSelectedStatus(option);
		setObjectStatus(option.value);
	}

	const selectCondition = (option) => {
		setSelectedCondition(option);
		setObjectCondition(option.value);
	}

	const selectAcquisitionType = (option) => {
		setSelectedAcquisitionType(option);
		setObjectAcquisitionType(option.value);
	}

  	return (
	  	<>
	  		<div className="App">
			  <AppHeader />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<div>
							<h2>{title}</h2>
							<PageBreadcrumb page={mode} />
						</div>
						<Link to={`/`} className="d-none d-sm-block ms-sm-auto"><Button variant="secondary" size="lg"><Icon name="chevrons-left" className="me-2" />Retour</Button></Link>
					</Stack>
					<Form onSubmit={handleSubmit}>
						<Tabs
							defaultActiveKey="object"
							id="justify-tab-example"
							className="mb-3"
							justify
							variant="pills"
							>
							<Tab eventKey="object" title={<React.Fragment><Icon name="rectangle-barcode" className="me-2" />Objet</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Type d'objet</Form.Label>
									<Select 
										options={typeOptions} 
										value={selectedType}
        								onChange={selectOptions}
										styles={selectStyles}
										components={{Option: CustomOption, SingleValue:CustomSingleValue}}
										isSearchable="false"
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Catégorie {constants.typesLabel[objectType]}</Form.Label>
									<Typeahead
										id="category-typeahead"
										allowNew
										placeholder={`Entrez la catégorie ${constants.typesLabel[objectType]}`} 
										selected={objectCat} 
										options={objectCategoryList}
										onChange={(event) => setObjectCat(event)}
										newSelectionPrefix='Nouvel élément : '
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Sous-catégorie {constants.typesLabel[objectType]}</Form.Label>
									<Typeahead
										id="subcategory-typeahead"
										allowNew
										placeholder={`Entrez la sous-catégorie ${constants.typesLabel[objectType]}`} 
										selected={objectSubcat} 
										options={objectSubcategoryList}
										onChange={(event) => setObjectSubcat(event)}
										newSelectionPrefix='Nouvel élément : '
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Check 
										type="switch"
										id="custom-switch"
										label="Objet multiple"
										checked={objectIsMultiple}
										onChange={() => setObjectIsMultiple(!objectIsMultiple)}
									/>
								</Form.Group>
								{objectIsMultiple && <Form.Group className="mb-3">
									<Form.Label>Quantité {constants.typesLabel[objectType]}</Form.Label>
									<Form.Control 
										type="number" min="1" step="1"
										placeholder="Entrez la quantité de l'objet" 
										value={objectQuantity} 
										onChange={(event) => setObjectQuantity(event.target.value)}
									/>
								</Form.Group>}

								
								
							</Tab>
							<Tab eventKey="data" title={<React.Fragment><Icon name="font" className="me-2" />Données</React.Fragment>}>
							<Form.Group className="mb-3">
									<Form.Label>Nom {constants.typesLabel[objectType]}</Form.Label>
									<Typeahead
										id="name-typeahead"
										allowNew
										onChange={(event) => { setObjectName(event); }}
										onInputChange={(string) => { setObjectName([string]);  }}
										options={objectNameList}
										placeholder={`Entrez le nom ${constants.typesLabel[objectType]}`} 
										selected={objectName} 
										newSelectionPrefix='Nouvel élément : '
									/>
								</Form.Group>
								<ObjectDataFormGroup 
									objectType={objectType} 
									objectDatas={objectDatas}
									setObjectDatas={setObjectDatas}
								/>
							</Tab>
							<Tab eventKey="acquisition" title={<React.Fragment><Icon name="bags-shopping" className="me-2" />Acquisition</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Date d'acquisition {constants.typesLabel[objectType]}</Form.Label>
									<Form.Control 
										type="date" 
										placeholder={`Entrez la date d'acquisition ${constants.typesLabel[objectType]}`} 
										value={objectAcquisitionDate} 
										onChange={(event) => setObjectAcquisitionDate(event.target.value)}
									/>
								</Form.Group>
								
								<Form.Group className="mb-3">
									<Form.Label>Type d'acquisition {constants.typesLabel[objectType]}</Form.Label>
									<Select 
										options={acquisitionTypeOptions} 
										value={selectedAcquisitionType}
        								onChange={selectAcquisitionType}
										styles={selectStyles}
										placeholder={`Sélectionnez le type d'acquisition ${constants.typesLabel[objectType]}`} 
										components={{Option: CustomOption, SingleValue:CustomSingleValue}}
									/>
								</Form.Group>
								{objectAcquisitionType === "purchase" && <Form.Group className="mb-3">
									<Form.Label>Magasin d'achat {constants.typesLabel[objectType]}</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Entrez le magasin d'achat de l'objet" 
										value={objectAcquisitionShop} 
										onChange={(event) => setObjectAcquisitionShop(event.target.value)}
									/>
								</Form.Group>}
								{objectAcquisitionType === "gift" && <Form.Group className="mb-3">
									<Form.Label>Offreur {constants.typesLabel[objectType]}</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Entrez l'offreur de l'objet" 
										value={objectAcquisitionGiver} 
										onChange={(event) => setObjectAcquisitionGiver(event.target.value)}
									/>
								</Form.Group>}
								
							</Tab>
							<Tab eventKey="state" title={<React.Fragment><Icon name="wine-glass-crack" className="me-2" />État</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>État de l'objet</Form.Label>
									<Select 
										options={conditionOptions} 
										value={selectedCondition}
        								onChange={selectCondition}
										placeholder="Sélectionnez l'état actuel de l'objet"
										styles={selectStyles} isClearable="true" 
										components={{Option: CustomOption, SingleValue:CustomSingleValue}}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Statut de l'objet</Form.Label>
									<Select 
										options={statusOptions} 
										value={selectedStatus}
        								onChange={selectStatus}
										placeholder="Sélectionnez le statut actuel de l'objet"
										styles={selectStyles} isClearable="true"
										components={{Option: CustomOption, SingleValue:CustomSingleValue}}
									/>
								</Form.Group>
								
							</Tab>
							<Tab eventKey="location" title={<React.Fragment><Icon name="binoculars" className="me-2" />Emplacement</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Emplacement actuel de l'objet</Form.Label>
									{/* <Form.Control 
										type="text" 
										placeholder="Entrez l'emplacement actuel de l'objet" 
										value={objectLocationPlace} 
										onChange={(event) => setObjectLocationPlace(event.target.value)} /> */}
									<Typeahead
										id="location-place-typeahead"
										allowNew
										placeholder="Entrez l'emplacement actuel de l'objet" 
										selected={objectLocationPlace} 
										options={objectLocationPlaceList}
										onChange={(event) => setObjectLocationPlace(event)}
										newSelectionPrefix='Nouvel élément : '
										// onInputChange={(event) => setObjectCat([event])}
									/>
								{/* /> */}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Lieu de stockage actuel {constants.typesLabel[objectType]}</Form.Label>
									<Typeahead
										id="location-storage-typeahead"
										allowNew
										placeholder={`Entrez le lieu de stockage ${constants.typesLabel[objectType]}`} 
										selected={objectLocationStorage} 
										options={objectLocationStorageList}
										onChange={(event) => setObjectLocationStorage(event)}
										newSelectionPrefix='Nouvel élément : '
									/>
								</Form.Group>
								{/* <Form.Group className="mb-3">
									<Form.Label>Lieu de stockage actuel de l'objet</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Entrez le lieu de stockage actuel de l'objet" 
										value={objectLocationStorage} 
										onChange={(event) => setObjectLocationStorage(event.target.value)}
									/>
								</Form.Group> */}
							</Tab>
							{/* <Tab eventKey="image" title={<React.Fragment><Icon name="camera" className="me-2" />Image</React.Fragment>}>
								
							</Tab> */}
							<Tab eventKey="value" title={<React.Fragment><Icon name="money-bill" className="me-2" />Valeur</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Valeur initial {constants.typesLabel[objectType]}</Form.Label>
									<Form.Control 
										type="number" min="0" step="0.01"
										placeholder="Entrez la valeur initiale de l'objet" 
										value={objectInitialValue} 
										onChange={(event) => setObjectInitialValue(event.target.value)}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Valeur actuelle de l'objet</Form.Label>
									<Form.Control 
										type="number" min="0" step="0.01"
										placeholder="Entrez la valeur actuelle de l'objet" 
										value={objectActualValue} 
										onChange={(event) => setObjectActualValue(event.target.value)}
									/>
								</Form.Group>
							</Tab>	
							<Tab eventKey="files" title={<React.Fragment><Icon name="files" className="me-2" />Fichiers</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Image de l'objet</Form.Label>
									{objectPicture !== "" && 
										<div>
											<a href={`https://${objectPicture}`} target="_blank" rel="noreferrer">
												<img src={`https://${objectPicture}`} alt="object" />
											</a>
										</div>}
									{objectPicture === "" && <Form.Control 
										type="file" 
										onChange={handlePictureUpload}
									/>}
									{objectPicture !== "" && <>
									<div className="d-grid mt-3">
										<Button type="Button" variant="outline-danger" onClick={handlePictureDelete}>Supprimer l'image</Button>
										</div>
									</>}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Facture de l'objet</Form.Label>
									{objectAcquisitionInvoice === "" && <Form.Control 
										type="file" 
										onChange={handleInvoiceUpload}
									/>}
									{objectAcquisitionInvoice !== "" && <>
									<a href={`https://${objectAcquisitionInvoice}`} target="_blank" rel="noreferrer">
										<div className="d-grid">
											<Button type="Button" variant="outline-secondary">Voir la facture</Button>
										</div>
									</a>
									<div className="d-grid mt-3">
										<Button type="Button" variant="outline-danger" onClick={handleInvoiceDelete}>Supprimer la facture</Button>
										</div>
									</>}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Manuel de l'objet</Form.Label>
									{objectManual === "" && <Form.Control 
										type="file" 
										onChange={handleManualUpload}
									/>}
									{objectManual !== "" && <>
									<a href={`https://${objectManual}`} target="_blank" rel="noreferrer">
										<div className="d-grid">
											<Button type="Button" variant="outline-secondary">Voir le manuel</Button>
										</div>
									</a>
									<div className="d-grid mt-3">
										<Button type="Button" variant="outline-danger" onClick={handleManualDelete}>Supprimer le manuel</Button>
										</div>
									</>}
								</Form.Group>
							</Tab>
							{/* <Tab eventKey="notes" title={<React.Fragment><Icon name="pen-nib" className="me-2" />Notes</React.Fragment>}>
								
							</Tab> */}
							 
							<Tab eventKey="infos" title={<React.Fragment><Icon name="info" className="me-2" />Informations</React.Fragment>}>
								<Form.Group className="mb-3">
									<Form.Label>Notes</Form.Label>
									<MDEditor
										value={objectNotes}
										onChange={setObjectNotes}
									/>
								</Form.Group>
								{mode === "edit" && <><Stack direction="horizontal" className="mb-3 flex-column flex-sm-row">
									<p className='text-start'><strong>Création</strong><br/><span className='text-muted'>{createDate}<br/>{object.creator}</span></p>
									<p className="ms-sm-auto text-end"><strong>Dernière édition</strong><br/><span className='text-muted'>{lastUpdateDate}<br/>{object.last_updator}</span></p>
								</Stack>
								<Form.Group className="mb-5">
										{/* <p>Nombre de modifications : </p> */}
										<Accordion>
										<Accordion.Item eventKey="0">
											<Accordion.Header>Nombre de modifications<Badge bg="primary" className='ms-3'>{object.update_count}</Badge></Accordion.Header>
											<Accordion.Body className='p-0'>
												<Table bordered responsive variant={appTheme == "dark" && 'dark'}>
													<tbody>
													{objectUpdates.map((update) => {
														return(
														<tr key={update.id}>
															<td className='text-primary'>{update.data === "object" ? update.type === "UPDATE" ? "SAVE" : "CREATE" : update.type}</td>
															{update.data === "object" && <td colSpan={3}>Objet</td>}
															{update.data !== "object" && <td>{constants.propertiesLabel[update.data] ? constants.propertiesLabel[update.data] : update.data}</td>}
															{update.data !== "object" && <td className='text-muted text-decoration-line-through'>{update.old_value}</td>}
															{update.data !== "object" && <td>{update.new_value}</td>}
															<td className='nowrap text-muted'>{new Date(update.date).toLocaleString("fr")}</td>
															<td className='nowrap text-muted'>{update.updator}</td>
														</tr>)
													})}
													</tbody>
												</Table>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Form.Group>
								<Form.Group className="mb-3">
									<div className="text-start">
										<JSONPretty id="json-pretty" data={JSON.stringify(object,null,'\t')}></JSONPretty>
									</div>
								</Form.Group>					
								<Form.Group className="mb-3 d-grid">
									<Button type="button" variant="danger" size="lg" onClick={handleDelete}>Supprimer l'objet</Button>
								</Form.Group></>}
							</Tab> 

						</Tabs>
						<Form.Group className="mt-4 pt-4 border-top d-grid">
							<Button type="submit" size="lg">{mode === "add" ? <><Icon name="plus" className="me-2" />Ajouter</> : <><Icon name="check" className="me-2" />Enregistrer</> }</Button>
							<Link to={`/`} className="d-grid d-sm-none mt-3"><Button variant="secondary" size="lg"><Icon name="chevrons-left" className="me-2" />Retour</Button></Link>
						</Form.Group>
					</Form>
				</section>
			</div>
			<ToastContainer position="bottom-end" style={{paddingBottom: 20,paddingRight: 20}}>
				{lastAction === "edit" && <BasicToast title="Objet modifié !" message="L'objet à bien été modifié" />}
			</ToastContainer>
	    </>
  	);
}

export default ObjectForm;