import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';

import constants from '../constants.jsx';
import { setColors, getConditionData, getStatusData } from '../libs/utils';

import { AppContext } from '../contexts/AppProvider.jsx';
import { ObjectsContext } from '../contexts/ObjectsProvider.jsx';

import Icon from '../ui/Icon.jsx';

function ObjectsTable({ objects, setObjects, isLocation, isCategory, isStatus, isCondition, filter, defaultOrder }) {

    const [order, setOrder] = useState("");
    const [order2, setOrder2] = useState("");
    

    const { appLang, appTheme } = useContext(AppContext);
    const { 
        objectsViewType,
        objectsShowCondition, 
        objectsShowStatus, 
        objectsShowCreation, 
        objectsShowLocation, 
        objectsShowCategory 
    } = useContext(ObjectsContext);

    const handleChangeOrder = (event) => {
		const field = event.target.dataset.field;
		const order = event.target.dataset.order ? event.target.dataset.order : "";
		setOrder(field);
		let newOrder = "";
		if(order === "" || order === "DESC") {
			event.target.dataset.order = "ASC";
			newOrder = 'ASC';
		}
		else if(order === "ASC") {
			event.target.dataset.order = "DESC";
			newOrder = "DESC";
		}
        setOrder2(newOrder);
		const newObjects = [...currentObjects];
		newObjects.sort((a, b) => {
            let nameA = "";
            let nameB = "";
            if(field === "status") {
                nameA = constants.statusOrder[a[field]] || constants.statusOrder.length + 1;
                nameB = constants.statusOrder[b[field]] || constants.statusOrder.length + 1;
            }
            else if(field === "condition") {
                nameA = constants.conditionsOrder[a[field]] || constants.conditionsOrder.length + 1;
                nameB = constants.conditionsOrder[b[field]] || constants.conditionsOrder.length + 1;
            }
            else {
                nameA = a[field].toUpperCase(); // ignore upper and lowercase
			    nameB = b[field].toUpperCase(); // ignore upper and lowercase
            }
			
			if (nameA < nameB) {
				return (newOrder === "ASC") ? -1 : 1;
			}
			if (nameA > nameB) {
				return (newOrder === "ASC") ? 1 : -1;
			}
			return 0;
		});
		setObjectsTabled(newObjects);
    }
    
    let currentObjects = objects;
    if(isCategory !== undefined) {
        let results = objects.filter((object) => {
            return (object.subcategory == filter);
        });
        currentObjects = results;
    }

    if(isLocation !== undefined) {
        let results = objects.filter((object) => {
            return (object.location_storage == filter);
        });
        currentObjects = results;
    }

    useLayoutEffect(() => {
		setColors();
	});

    const [objectsTabled, setObjectsTabled] = useState([]);

    useEffect(() => {
        setObjectsTabled(currentObjects);
    },[objects]);

    if(objectsViewType !== "all" && !isCategory && !isLocation && !isStatus && !isCondition) {

        if(objectsViewType === "object") {
            return (
                <Table bordered responsive variant={appTheme} className='nowrap'>
                    <thead>
                        <tr>
                            <th></th>
                            <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            <th onClick={handleChangeOrder} className={order === "brand" ?"active d-flex":""} data-field="brand">Marque{order === "brand" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            <th onClick={handleChangeOrder} className={order === "model" ?"active d-flex":""} data-field="model">Modèle{order === "model" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                            {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                            {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {objectsTabled.map((object) =><tr key={object.id+" "+object.name}>
                            <td style={{width:30,height:30,textAlign:"center"}}>
                                {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                            </td>
                            <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link>{object.quantity > 1 && <span className='text-muted'>{' x'+object.quantity}</span>}</td>
                            <td style={{lineHeight: '40px',paddingRight:'20px',width:250,maxWidth:250}}>{object.brand}</td>
                            <td style={{lineHeight: '40px',width:250,maxWidth:250,overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{object.model}</td>
                            {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                            {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                            {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{object.condition && <><Link to={`/condition/${object.condition}`} className="text-theme"><Icon name={getConditionData(object.condition).icon} className="me-2" />{getConditionData(object.condition).label}</Link></>}</td>}
                            {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{object.status && <><Link to={`/status/${object.status}`} className="text-theme"><Icon name={getStatusData(object.status).icon} className="me-2" />{getStatusData(object.status).label}</Link></>}</td>}
                            {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                            <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                                <Link to={`/edit/${object.id}`}>
                                    <Button variant="primary"><Icon name="pencil" /></Button>
                                </Link>
                            </td>
                        </tr> )}
                    </tbody>
                </Table>
            )
        }
        if(objectsViewType === "book") {
            return (
                <Table bordered responsive variant={appTheme} className='nowrap'>
                    <thead>
                        <tr>
                            <th></th>
                            <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            <th onClick={handleChangeOrder} className={order === "author" ?"active d-flex":""} data-field="model">Auteur{order === "author" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                            {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                            {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {objectsTabled.map((object) =><tr key={object.id+" "+object.name}>
                            <td style={{width:30,height:30,textAlign:"center"}}>
                                {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                            </td>
                            <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                            <td style={{lineHeight: '40px',width:250,maxWidth:250}}>{object.author}</td>
                            {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                            {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                            {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                            {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                            {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                            <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                                <Link to={`/edit/${object.id}`}>
                                    <Button variant="primary"><Icon name="pencil" /></Button>
                                </Link>
                            </td>
                        </tr> )}
                    </tbody>
                </Table>
            )
        }
        if(objectsViewType === "clothes") {
            return (
                <Table bordered responsive variant={appTheme} className='nowrap'>
                    <thead>
                        <tr>
                            <th></th>
                            <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            <th onClick={handleChangeOrder} className={order === "brand" ?"active d-flex":""} data-field="brand">Marque{order === "brand" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                            {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                            {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {objectsTabled.map((object) =><tr key={object.id+" "+object.name}>
                            <td style={{width:30,height:30,textAlign:"center"}}>
                                {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                            </td>
                            <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                            <td style={{lineHeight: '40px',paddingRight:'20px',width:250,maxWidth:250}}>{object.brand}</td>
                            {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                            {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                            {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                            {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                            {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                            <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                                <Link to={`/edit/${object.id}`}>
                                    <Button variant="primary"><Icon name="pencil" /></Button>
                                </Link>
                            </td>
                        </tr> )}
                    </tbody>
                </Table>
            )
        }
        if(objectsViewType === "vinyl") {
            return (
                <Table bordered responsive variant={appTheme} className='nowrap'>
                    <thead>
                        <tr>
                            <th></th>
                            <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            <th onClick={handleChangeOrder} className={order === "author" ?"active d-flex":""} data-field="model">Artiste{order === "artist" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                            {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isLocation && objectsShowLocation &&<th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                            {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                            {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                            {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {objectsTabled.map((object) =><tr key={object.id+" "+object.name}>
                            <td style={{width:30,height:30,textAlign:"center"}}>
                                {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                            </td>
                            <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                            <td style={{lineHeight: '40px',width:250,maxWidth:250}}>{object.artist}</td>
                            {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                            {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                            {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                            {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                            {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                            <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                                <Link to={`/edit/${object.id}`}>
                                    <Button variant="primary"><Icon name="pencil" /></Button>
                                </Link>
                            </td>
                        </tr> )}
                    </tbody>
                </Table>
            )
        }

    }
    
    return (<>
        {objectsTabled.filter(object => object.type === "object").length > 0 && <><p><Icon name="rectangle-barcode" className="me-2" />Objets</p><Table bordered responsive variant={appTheme} className='nowrap'>
            <thead>
                <tr>
                    <th></th>
                    <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    <th onClick={handleChangeOrder} className={order === "brand" ?"active d-flex":""} data-field="brand">Marque{order === "brand" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    <th onClick={handleChangeOrder} className={order === "model" ?"active d-flex":""} data-field="model">Modèle{order === "model" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                    {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                    {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {objectsTabled.filter(object => object.type === "object").map((object) =><tr key={object.id+" "+object.name}>
                    <td style={{width:30,height:30,textAlign:"center"}}>
                        {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                    </td>
                    <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                    <td style={{lineHeight: '40px',paddingRight:'20px',width:250,maxWidth:250}}>{object.brand}</td>
                    <td style={{lineHeight: '40px',width:250,maxWidth:250,overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{object.model}</td>
                    {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                    {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                    {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                    {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                    {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                    <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                        <Link to={`/edit/${object.id}`}>
                            <Button variant="primary"><Icon name="pencil" /></Button>
                        </Link>
                    </td>
                </tr> )}
            </tbody>
        </Table></>}
        {objectsTabled.filter(object => object.type === "book").length > 0 && <><p><Icon name="books" className="me-2" />Livres</p><Table bordered responsive variant={appTheme} className='nowrap'>
            <thead>
                <tr>
                    <th></th>
                    <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    <th onClick={handleChangeOrder} className={order === "author" ?"active d-flex":""} data-field="model">Auteur{order === "author" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                    {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                    {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {objectsTabled.filter(object => object.type === "book").map((object) =><tr key={object.id+" "+object.name}>
                    <td style={{width:30,height:30,textAlign:"center"}}>
                        {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                    </td>
                    <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                    <td style={{lineHeight: '40px',width:250,maxWidth:250}}>{object.author}</td>
                    {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                    {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                    {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                    {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                    {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                    <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                        <Link to={`/edit/${object.id}`}>
                            <Button variant="primary"><Icon name="pencil" /></Button>
                        </Link>
                    </td>
                </tr> )}
            </tbody>
        </Table></>}
        {objectsTabled.filter(object => object.type === "clothes").length > 0 && <><p><Icon name="clothes-hanger" className="me-2" />Vêtements</p><Table bordered responsive variant={appTheme} className='nowrap'>
            <thead>
                <tr>
                    <th></th>
                    <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    <th onClick={handleChangeOrder} className={order === "brand" ?"active d-flex":""} data-field="brand">Marque{order === "brand" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                    {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                    {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {objectsTabled.filter(object => object.type === "clothes").map((object) =><tr key={object.id+" "+object.name}>
                    <td style={{width:30,height:30,textAlign:"center"}}>
                        {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                    </td>
                    <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                    <td style={{lineHeight: '40px',paddingRight:'20px',width:250,maxWidth:250}}>{object.brand}</td>
                    {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                    {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                    {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                    {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                    {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                    <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                        <Link to={`/edit/${object.id}`}>
                            <Button variant="primary"><Icon name="pencil" /></Button>
                        </Link>
                    </td>
                </tr> )}
            </tbody>
        </Table></>}
        {objectsTabled.filter(object => object.type === "vinyl").length > 0 && <><p><Icon name="turntable" className="me-2" />Vinyl</p><Table bordered responsive variant={appTheme} className='nowrap'>
            <thead>
                <tr>
                    <th></th>
                    <th onClick={handleChangeOrder} className={order === "name" ?"active d-flex":""} data-field="name">Nom{order === "name" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    <th onClick={handleChangeOrder} className={order === "author" ?"active d-flex":""} data-field="model">Artiste{order === "artist" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>
                    {!isCategory && objectsShowCategory && <th onClick={handleChangeOrder} className={order === "category" ?"active d-flex":""} data-field="category">Catégorie{order === "category" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isLocation && objectsShowLocation && <th onClick={handleChangeOrder} className={order === "location_place" ?"active d-flex":""} data-field="location_place">Emplacement{order === "location_place" && <Icon name={order2 == "ASC" ? "arrow-up-a-z" : "arrow-down-a-z" } className="text-muted ms-auto mt-1" />}</th>}
                    {!isCondition && objectsShowCondition && <th onClick={handleChangeOrder} className={order === "condition" ?"active d-flex":""} data-field="condition">État</th>}
                    {!isStatus && objectsShowStatus && <th onClick={handleChangeOrder} className={order === "status" ?"active d-flex":""} data-field="status">Statut</th>}
                    {objectsShowCreation && <th onClick={handleChangeOrder} className={order === "create_date" ?"active d-flex":""} data-field="create_date">Création{order === "create_date" && <Icon name={order2 == "ASC" ? "arrow-up-1-9" : "arrow-down-1-9" } className="text-muted ms-auto mt-1" />}</th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {objectsTabled.filter(object => object.type === "vinyl").map((object) =><tr key={object.id+" "+object.name}>
                    <td style={{width:30,height:30,textAlign:"center"}}>
                        {object.picture && <div className='picture-wrapper'><img src={"https://"+object.picture} style={{ maxWidth:30,maxHeight:30 }} alt="object" /></div>}
                    </td>
                    <td style={{lineHeight: '40px'}}><Link to={`/view/${object.id}`}>{object.name}</Link></td>
                    <td style={{lineHeight: '40px',width:250,maxWidth:250}}>{object.artist}</td>
                    {!isCategory && objectsShowCategory && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/category/${object.category}`} className="text-theme">{object.category}</Link><br/><small>{object.subcategory}</small></td>}
                    {!isLocation && objectsShowLocation && <td style={{lineHeight: '20px',width:250,maxWidth:250}}><Link to={`/location/${object.location_place}`} className="text-theme">{object.location_place}</Link><br/><small>{object.location_storage}</small></td>}
                    {!isCondition && objectsShowCondition && <td style={{lineHeight: '40px'}}>{constants.conditions[object.condition]}</td>}
                    {!isStatus && objectsShowStatus && <td style={{lineHeight: '40px'}}>{constants.status[object.status]}</td>}
                    {objectsShowCreation && <td className='text-muted' style={{width:110,lineHeight:'40px',paddingRight:'20px'}}>{new Date(object.create_date).toLocaleDateString(appLang)}</td>}
                    <td style={{width:60,lineHeight:'40px',textAlign:"center"}}>
                        <Link to={`/edit/${object.id}`}>
                            <Button variant="primary"><Icon name="pencil" /></Button>
                        </Link>
                    </td>
                </tr> )}
            </tbody>
        </Table></>}
        </>
    )

}

export default ObjectsTable;