import React, { useState, useEffect } from 'react';
import ObjectsTable from './ObjectsTable.jsx';

function ConditionTable({ objects, setObjects }) {

    return (
        <ObjectsTable isCondition objects={objects} setObjects={setObjects} defaultOrder="status" />
    )

}

export default ConditionTable;